export const StarIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      width='11'
      height='11'
      viewBox='0 0 11 11'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.78271 1.21047C5.05999 0.590651 5.93987 0.59065 6.21715 1.21047L7.0197 3.00446C7.13013 3.25131 7.35973 3.42402 7.62752 3.46167L9.55607 3.73284C10.1958 3.82279 10.4596 4.60337 10.0057 5.063L8.53041 6.55684C8.35201 6.73748 8.27188 6.99319 8.31531 7.24333L8.67602 9.32122C8.79008 9.97826 8.08536 10.4697 7.50823 10.1356L5.8936 9.20083C5.65009 9.05986 5.34978 9.05986 5.10627 9.20083L3.49164 10.1356C2.91451 10.4697 2.20978 9.97826 2.32384 9.32122L2.68456 7.24333C2.72798 6.99319 2.64786 6.73748 2.46946 6.55684L0.994159 5.06301C0.540224 4.60337 0.804086 3.82279 1.4438 3.73284L3.37235 3.46167C3.64014 3.42402 3.86974 3.25131 3.98017 3.00446L4.78271 1.21047Z'
        fill='currentColor'
      />
    </svg>
  );
};
