import { type MediaFormatVersion } from '@lp-lib/media';

import { type Tag } from '../../../src/types';
import { fromMediaDTO } from '../../../src/utils/api-dto';
import { getStaticAssetPath } from '../../../src/utils/assets';
import { MediaUtils } from '../../../src/utils/media';

export function TagSupportingMedia(props: {
  tag: Tag;
  priority?: readonly MediaFormatVersion[];
  className?: string;
}) {
  const { tag, priority, className } = props;

  const mediaUrl =
    MediaUtils.PickMediaUrl(fromMediaDTO(tag.supportingMedia?.media), {
      priority,
    }) || getStaticAssetPath('images/tag-supporting-default.png');

  return (
    <img
      className={className}
      style={{
        aspectRatio: '1/1',
      }}
      src={mediaUrl}
      alt=''
    />
  );
}
