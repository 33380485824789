import { type Tag } from '../../../src/types';

export function getTagStyles(tag: Tag) {
  const titleTextColor = tag.styles?.titleTextColor || '#FFFFFF';
  const backgroundFromColor = tag.styles?.backgroundColor1 || '#FF0935';
  const backgroundToColor = tag.styles?.backgroundColor2 || '#0029FF';

  return {
    titleTextColor,
    backgroundFromColor,
    backgroundToColor,
    background: `linear-gradient(to right bottom, ${backgroundFromColor}, ${backgroundToColor})`,
  };
}
